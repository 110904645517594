<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <p class="p-title">积分配置编辑</p>
            <el-form :inline="true" ref="form" v-bind:rules="rules" v-bind:model="form" label-position="left"
                     label-suffix=":"
                     size="large" label-width="110px" class="dialog_box">
                <div class="inline-form-item">
                    <div class="inline-form-cell"><span class="inline-cell-title">抵扣：</span>
                        <el-input v-model="form.deduct_trans_rate" placeholder="请输入数量" style="margin-bottom:10px;"></el-input>
                    </div>
                    <div class="inline-form-cell">
                        <span class="inline-cell-text">积分抵扣1元，抵扣上限，毛利</span></div>
                    <div class="inline-form-cell">
                        <el-input v-model="form.deduct_rate_limit" placeholder="请输入数量" style="margin-bottom:10px;"></el-input>
                        <span class="inline-cell-text"> %</span>
                    </div>
                </div>
                <div class="inline-form-item">
                    <div class="inline-form-cell"><span class="inline-cell-title">抽奖：</span>
                        <el-input v-model="form.luckydraw_trans_rate" placeholder="请输入数量" style="margin-bottom:10px;"></el-input>
                    </div>
                    <div class="inline-form-cell"><span class="inline-cell-text">积分参与一次，每</span></div>
                    <div class="inline-form-cell">
                        <el-input v-model="form.luckydraw_day_range" placeholder="请输入数量" style="margin-bottom:10px;"></el-input>
                        <span class="inline-cell-text"> 天参与</span>
                    </div>
                    <div class="inline-form-cell">
                        <el-input v-model="form.luckydraw_range_limit" placeholder="请输入数量" style="margin-bottom:10px;"></el-input>
                        <span class="inline-cell-text"> 次</span>
                    </div>
                </div>
                <div class="inline-form-item">
                    <div class="inline-form-cell"><span class="inline-cell-title">兑换：</span>
                        <el-input v-model="form.integral_trans_num" placeholder="请输入数量" style="margin-bottom:10px;"></el-input>
                        <span class="inline-cell-text"> 积分兑换</span>
                    </div>
                    <div class="inline-form-cell">
                        <el-input v-model="form.card_trans_num" placeholder="请输入数量" style="margin-bottom:10px;"></el-input>
                        <span class="inline-cell-text"> 张月卡</span>
                    </div>
                </div>
                <div class="inline-form-item">
                    <div class="inline-form-cell"><span class="inline-cell-title">购物：</span></div>
                    <div class="inline-form-cell"><span class="inline-cell-text">订单金额满</span>
                        <el-input v-model="form.buy_over_amount" placeholder="请输入数量" style="margin-bottom:10px;"></el-input>
                        <span class="inline-cell-text"> 元，</span>
                    </div>
                    <div class="inline-form-cell"><span class="inline-cell-text"></span>
                        <el-input v-model="form.buy_give_integral" placeholder="请输入数量" style="margin-bottom:10px;"></el-input>
                        <span class="inline-cell-text">%，赠送积分（积分数按金额比例抵扣转换）</span>
                    </div>
                </div>
                <el-divider></el-divider>
                <el-form-item label="名称" prop="title">
                    <el-input v-model="form.title" placeholder="请输入配置名称" style="margin-bottom:10px;"></el-input>
                </el-form-item>
                <br>
                <el-form-item label="排序" prop="r">
                    <el-input v-model="form.r" placeholder="请输入排序值" style="margin-bottom:10px;"></el-input>
                </el-form-item>
                <br>
                <el-form-item label="起止时间" prop="time">
                    <el-date-picker ref="drawDate" :picker-options="pickerOptions" style="margin-bottom:10px;"
                                    v-model="form.time"
                                    value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <br>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
                <br>
                <el-form-item>
                    <el-button type="primary" @click="saveData">保 存</el-button>
                    <el-button @click="cancel">取 消</el-button>
                </el-form-item>

            </el-form>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import loadEvents from '@/utils/loading'
import Imgupload from '@/components/Imgupload';
import {deal} from '@/common/main';
import {request} from '@/common/request';
import bus from '@/common/bus';
import Picture from '@/components/Picture';

let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Imgupload,
        Picture
    },
    data() {
        return {
            ImgVisible: false,
            text: 'image',
            mode: 'add',
            menuList: ['营销', '积分活动配置'],
            loading: false,
            rules: {
                title: [{
                    required: true,
                    message: '活动名称不能为空',
                    trigger: 'blur'
                }],
                time: [{
                    required: true,
                    message: '活动时间不能为空',
                    trigger: 'blur'
                }],

                thumbs: [{validator: deal.checkImage, trigger: ['blur', 'change'], required: true,}],


                state: [{
                    required: true,
                    message: '请选择状态',
                    trigger: 'blur'
                }],


                position: [{
                    required: true,
                    message: '位置不能为空',
                    trigger: 'blur'
                }],
            },
            form: {
                state: 2,
            },
            header: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            positionList: [],
            thumbs: [],
            acceptRulers: {

            }
        };
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.query.id && to.query.id !== this.id && to.path === "/activity-IntegralConfigEdit") {
                    this.id = to.query.id
                    this.getDetail(this.id);
                }
            },
            deep: true,
                immediate: true
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
    },
    methods: {
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        this.form.start_day = deal.timestamp(this.form.time[0]);
                        this.form.end_day = deal.timestamp(this.form.time[1]);
                        request.post('/system/integralConfig/edit', this.form).then(ret => {
                            if (ret.code === 1) {
                                this.$message.success('新增成功');
                                this.$refs.form.clearValidate()
                                bus.$emit('close_current_tags');
                                this.$router.push({path: '/activity-IntegralConfig'})
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$refs.form.clearValidate();
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            }
            ;
        },
        // 获取详情数据
        getDetail(id) {
            request.get('/system/integralConfig/detail', { id: id }).then(ret => {
                if (ret.code == 1) {
                    deal.getconf('seckill', this)
                    let list = ret.data.list;
                    let time = [];
                    time[0] = deal.timestamp(list.start_day, 'unix');
                    time[1] = deal.timestamp(list.end_day, 'unix');
                    this.form = ret.data.list;
                    this.$set(this.form, 'time', time)
                    this.$nextTick(function () {
                        this.$refs.form.clearValidate();
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 取消按钮
        cancel() {
            bus.$emit('close_current_tags');
            this.$router.push({path: '/activity-SeckillSessionList'})
        },
        //
        handleCheckedHoursChange(val, before) {
        },

    }
};
</script>

<style scoped>
.inline-form-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 16px;
}

.inline-form-item .inline-form-cell .inline-cell-title {
    font-weight: bold;
    position: relative;
    top: 3px;
    font-size: 20px;
}

.inline-form-item .inline-form-cell .inline-cell-text {
    font-size: 16px;
    position: relative;
    top: 3px;
    margin: 0 5px;
}

.box >>> .el-form-item {
    margin-bottom: 20px;
}

.type_box .box >>> .el-input--large .el-input__inner {
    width: 150px;
    margin-right: 10px;
}

.el-textarea >>> .el-textarea__inner {
    width: 500px !important;
    height: 200px;
}

.p-title {
    font-size: 20px;
    font-weight: 900;
}

.dialog_box {
    padding: 30px;
}

.span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box >>> .el-select .el-input--large .el-input__inner {
    width: 400px;
}

.dialog_box >>> .el-input--large .el-input__inner {
    width: 201%;
}

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

.dialog_box .up {
    margin-top: 20px;
}

.dialog_box .img1 .avatar-uploader-icon {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .img1 .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .img2 .avatar-uploader-icon {
    font-size: 28px;
    width: 150px;
    height: 60px;
    line-height: 60px;
}

.dialog_box .img2.avatar {
    font-size: 28px;
    width: 150px;
    height: 60px;
    line-height: 60px;
}

.dialog_box .img3 .avatar-uploader-icon {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .img3.avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}


.container {
    background: #ffffff;
}

.form-input >>> .el-input__inner {
    width: 500px;
}

.el-input--small .el-input__inner {
    width: 500px;
}

/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 220px;
}

.type_box .el-button {
    margin-left: 20px;
}
</style>